<script setup lang="ts">
import { getSmallestThumbnailUrl } from '@shopware-pwa/helpers-next';
import { LineItem } from '@shopware-pwa/types';
import { CheckoutCartItem } from '@upa/design-system';

import { changeCartItemQuantity } from '@shopware-pwa/api-client';

const props = withDefaults(
  defineProps<{
    cartItem: LineItem;
    maxQty?: number;
  }>(),
  { maxQty: 100 },
);

const { cartItem } = toRefs(props);

const LineItemCard = cartItem.value as any;

const isLoading = ref(false);
const { codeErrorsNotification } = useCartNotification();

const { refreshCart } = useCart();

const { itemOptions, removeItem, itemTotalPrice, itemQuantity } =
  useCartItem(cartItem);

const quantity = ref();
syncRefs(itemQuantity, quantity);

const { apiInstance } = useShopwareContext();

/**
 * Define a function to handle product quantity changes asynchronously
 */
const handleProductQuantity = async (data: {
  productId: string;
  quantity: number;
}) => {
  isLoading.value = true;

  await changeCartItemQuantity(data.productId, data.quantity, apiInstance);

  await refreshCart();

  isLoading.value = false;
};

const debounceUpdate = useDebounceFn(handleProductQuantity, 800);

/**
 * Watch for changes in the quantity
 */
watch(quantity, async () => {
  await refreshCart();
});

/**
 * function to remove the current cart item
 */
const removeCartItem = async () => {
  isLoading.value = true;
  await removeItem();
  await refreshCart();
  isLoading.value = false;
};
</script>

<template>
  <CheckoutCartItem
    :product="LineItemCard"
    :is-offcanvas="true"
    :is-account="false"
    :has-quantity-btn="LineItemCard.hasQuantityBtn"
    @remove-from-cart="removeCartItem"
    @update:quantity="debounceUpdate"
  >
    <template #price>
      <SharedPrice
        v-if="itemTotalPrice"
        :value="itemTotalPrice"
        data-testid="cart-product-price"
        class="product-card-content__price"
      />
    </template>
  </CheckoutCartItem>
</template>
